import { useState, useRef } from "react";
import { Box, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function OTPInput({ onComplete }) {
  const navigate = useNavigate();

  const [otp, setOtp] = useState(new Array(6).fill(""));
  const inputRefs = useRef([]);

  const handleChange = (index, event) => {
    const value = event.target.value;
    if (!/^\d?$/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }

    if (newOtp.every((digit) => digit !== "")) {
      onComplete(newOtp.join(""));
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <>
      <p className="auth-subheading">Please enter the 6-digit OTP</p>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="left"
        gap={2}
        ml={"40px"}
      >
        <Box display="flex" gap={1}>
          {otp.map((digit, index) => (
            <TextField
              key={index}
              value={digit}
              onChange={(e) => handleChange(index, e)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              inputRef={(el) => (inputRefs.current[index] = el)}
              variant="outlined"
              size="small"
              inputProps={{
                maxLength: 1,
                style: { textAlign: "center", fontSize: "20px", width: "40px" }
              }}
            />
          ))}
        </Box>
        <Button
          className="primary-button-filled"
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
          sx={{ width: "90px" }}
        >
          Back
        </Button>
      </Box>
    </>
  );
}
