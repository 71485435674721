function SAPIcon() {
  return (
    <svg
      fill="#000000"
      width="28"
      height="28"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 8.083v15.835h16.172l15.828-15.835zM4.355 11.031h0.005c1.151 0 2.552 0.324 3.567 0.844l-1.093 1.907c-1.115-0.541-1.672-0.588-2.307-0.625-0.943-0.052-1.417 0.287-1.428 0.651-0.005 0.444 0.896 0.844 1.745 1.12 1.281 0.407 2.916 0.953 3.167 2.532l2.349-6.208h2.728l2.751 7.432-0.011-7.432h3.161c3.011 0 4.427 1.020 4.427 3.359 0 2.099-1.452 3.355-3.916 3.355h-1.016l-0.015 2.791-4.781-0.005-0.333-1.213c-0.495 0.167-1.052 0.256-1.641 0.256-0.609 0-1.183-0.095-1.687-0.265l-0.475 1.223-2.667 0.005 0.12-0.615c-0.041 0.037-0.079 0.068-0.12 0.1-0.708 0.572-1.609 0.839-2.713 0.859h-0.287c-1.203 0.016-2.391-0.296-3.437-0.896l0.973-1.932c1.052 0.625 1.715 0.76 2.605 0.745 0.463-0.011 0.796-0.095 1.015-0.319 0.131-0.135 0.204-0.307 0.209-0.495 0.009-0.5-0.709-0.733-1.579-1.005-0.708-0.219-1.516-0.52-2.141-0.984-0.744-0.541-1.104-1.229-1.083-2.197 0.005-0.688 0.276-1.344 0.755-1.833 0.693-0.719 1.808-1.152 3.152-1.152zM18.479 13.255v2.543h0.699c0.921 0 1.661-0.308 1.661-1.287 0-0.948-0.74-1.256-1.661-1.256zM11.697 14.281l-0.995 3.145c0.297 0.115 0.641 0.177 1.005 0.177 0.36 0 0.699-0.063 0.989-0.167l-0.979-3.161z" />
    </svg>
  );
}

export default SAPIcon;
