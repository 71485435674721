import { properties } from "../properties";
import api from "../services/api";

function addSessionConfiguration(values) {
  return api.post(
    `${properties.adminService}/session/ad-user/configuration`,
    values
  );
}

function getSessionConfiguration() {
  return api.get(`${properties.adminService}/session/ad-user/configuration`);
}

function updateSessionConfiguration(values) {
  return api.put(
    `${properties.adminService}/session/ad-user/configuration`,
    values
  );
}

function getAllActiveSession(pageSize, pageNumber, searchText) {
  return api.get(
    `${properties.adminService}/session/ad-user?searchText=${searchText}&pageSize=${pageSize}&pageNumber=${pageNumber}`
  );
}

export {
  getSessionConfiguration,
  addSessionConfiguration,
  updateSessionConfiguration,
  getAllActiveSession
};
