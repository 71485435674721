import Box from "@mui/material/Box";
import PageTitle from "../utility/PageTitle";
import { Outlet } from "react-router-dom";
import NavigationTabs from "../utility/NavigationTabs";
import { PRIVILEGES } from "../../utils/Privileges";

function AdUserPolicy({ setFeedback }) {
  
  const tabList = [
    {
      label: "Session Configuration",
      path: "/ad-user-policy/session-policy",
      requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name]
    },
    {
      label: "Adaptive Configuration",
      path: "/ad-user-policy/adaptive-policy",
      requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name]
    }
  ];

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <PageTitle subheading="" />
        <br />
        <NavigationTabs tabList={tabList} />
        <Box sx={{ pt: 2 }}>
          <Outlet />
        </Box>
      </Box>
    </div>
  );
}

export default AdUserPolicy;
