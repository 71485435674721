import { useEffect, useState } from "react";
import { getAllActiveSession } from "../../../API/ad_user_policy";
import TableComponent from "../../utility/Table";
import { formatDateTimeString } from "../../../utils/Helpers";
import SearchBar from "../../utility/SearchBar";
import Pagination from "../../utility/Pagination";

export default function AdUserActiveSession({ setFeedback }) {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    getAllActiveSession(rowsPerPage, page, searchText)
      .then((response) => {
        setTotalCount(response.data.totalCount);
        setData(
          response.data.data.map((item) => ({
            ...item,
            sessionStart: formatDateTimeString(item.sessionStart + "Z")
          }))
        );
      })
      .catch(() => {
        setFeedback({
          severity: "error",
          message: "There is an issue while fetching active sessions.",
          state: true
        });
      });
  }, [rowsPerPage, page, searchText]);

  const headings = [
    "deviceId",
    "deviceName",
    "username",
    "ipAddress",
    "sessionStart"
  ];
  return (
    <div style={{ textAlign: "left" }}>
      <SearchBar
        setSearchText={setSearchText}
        searchText={searchText}
        placeholder={"Search by username"}
        styles={{ marginRight: "10px" }}
      />
      <TableComponent headings={headings} data={data} />
      {totalCount != 0 ? (
        <div className="pagination">
          <Pagination
            totalCount={totalCount}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </div>
      ) : null}
    </div>
  );
}
