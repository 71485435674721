import { Box, FormControlLabel, FormGroup } from "@mui/material";
import StyledSwitch from "./StyledSwitch";
import { setAutoRefresh } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";

export default function AutoRefresh({ getListHandler, autoRefreshCount }) {
  const [refreshTime, setRefreshTime] = useState(5000);

  const dispatch = useDispatch();
  const autoRefresh = useSelector((state) => state.auth.isAutoRefresh);

  const handleRefreshChange = (event) => {
    dispatch(setAutoRefresh(event.target.checked));
  };

  useEffect(() => {
    if (autoRefresh) {
      const refreshInterval = setInterval(() => {
        getListHandler();
      }, refreshTime);
      return () => clearInterval(refreshInterval);
    }
  }, [autoRefreshCount, autoRefresh]);

  return (
    <Box className="d-flex">
      <FormGroup>
        <FormControlLabel
          control={<StyledSwitch />}
          label="Auto Refresh"
          checked={autoRefresh}
          onClick={handleRefreshChange}
        />
      </FormGroup>
    </Box>
  );
}
