import { properties } from "../properties";
import api from "../services/api";

function addAdaptiveConfiguration(data) {
  return api.post(
    `${properties.adminService}/ad-user/adaptive-configuration`,
    data
  );
}

function getAdaptiveConfiguration() {
  return api.get(`${properties.adminService}/ad-user/adaptive-configuration`);
}

function updateAdaptiveConfiguration(values) {
  return api.put(
    `${properties.adminService}/ad-user/adaptive-configuration`,
    values
  );
}

export {
  addAdaptiveConfiguration,
  getAdaptiveConfiguration,
  updateAdaptiveConfiguration
};
