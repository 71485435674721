import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  MenuItem,
  Button,
  Box,
  FormControl,
  Select
} from "@mui/material";
import CustomTooltip from "../../utils/Tooltip";
import {
  addSessionConfiguration,
  getSessionConfiguration,
  updateSessionConfiguration
} from "../../API/ad_user_policy";
import StyledSwitch from "../utility/StyledSwitch";
import CircularLoader from "../assets/CircularLoader";

const validationSchema = Yup.object({
  concurrentSessions: Yup.number()
    .min(1, "Must be at least 1")
    .max(3, "Must be less than 4")
    .required("Required"),
  actionOnExceed: Yup.string().required("Required"),
  inactivityPeriod: Yup.number()
    .min(1, "Must be at least 1 minute")
    .required("Required"),
  notifyAdmin: Yup.boolean(),
  notifyEndUser: Yup.boolean()
});

const SessionPolicy = ({ setFeedback }) => {
  const [formType, setFormType] = useState("update");
  const [loading, setLoading] = useState(false);

  const Action = {
    TERMINATE_ALL:"TERMINATE_ALL",
    DENY_CURRENT:"DENY_CURRENT",
    TERMINATE_OLDEST:"TERMINATE_OLDEST"
  };

  const handleSubmit = (values) => {
    let promise;
    if (formType == "add") {
      promise = addSessionConfiguration(values);
    } else {
      promise = updateSessionConfiguration(values);
    }

    promise
      .then(() => {
        setFeedback({
          severity: "success",
          message: "Successfully saved!",
          state: true
        });
      })
      .catch(() => {
        setFeedback({
          severity: "error",
          message: "There is an issue while saving configuration!",
          state: true
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      isEnabled: false,
      concurrentSessions: "",
      actionOnExceed: Action.DENY_CURRENT,
      inactivityPeriod: "",
      notifyAdmin: false,
      notifyEndUser: false
    },
    validationSchema,
    onSubmit: handleSubmit
  });

  useEffect(() => {
    setLoading(true);
    getSessionConfiguration()
      .then((response) => {
        if (response.data) formik.setValues(response.data);
        else setFormType("add");
      })
      .catch(() => {
        setFeedback({
          severity: "error",
          message: "There is an issue while fetching configuration!",
          state: true
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return loading ? (
    <CircularLoader />
  ) : (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{ textAlign: "left", p: 2 }}
    >
      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Grid item xs={4}>
          Enable Sesion Restriction
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <StyledSwitch
                name="isEnabled"
                checked={formik.values.isEnabled}
                onChange={formik.handleChange}
                sx={{ marginRight: "20px" }}
              />
            }
            sx={{ marginLeft: "0px" }}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography textAlign="left">
            Number of Concurrent Sessions
            <CustomTooltip title="Set the maximum number of concurrent sessions allowed." />
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="number"
            fullWidth
            name="concurrentSessions"
            size="small"
            value={formik.values.concurrentSessions}
            onChange={formik.handleChange}
            error={
              formik.touched.concurrentSessions &&
              Boolean(formik.errors.concurrentSessions)
            }
            helperText={
              formik.touched.concurrentSessions &&
              formik.errors.concurrentSessions
            }
          />
        </Grid>

        <Grid item xs={4}>
          <Typography textAlign="left">
            Action on Exceed
            <CustomTooltip title="Choose the action when concurrent session limit is exceeded." />
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <Select
              name="actionOnExceed"
              size="small"
              value={formik.values.actionOnExceed}
              onChange={formik.handleChange}
              error={
                formik.touched.actionOnExceed &&
                Boolean(formik.errors.actionOnExceed)
              }
            >
              <MenuItem value={Action.TERMINATE_ALL}>
                Terminate All Previous Sessions
              </MenuItem>
              <MenuItem value={Action.TERMINATE_OLDEST}>
                Terminate Oldest Session
              </MenuItem>
              <MenuItem value={Action.DENY_CURRENT}>
                Deny Access to Current Session
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Typography textAlign="left">
            Notify On Session Exceed
            <CustomTooltip title="Enable this option to notify the admin when session limits are exceeded." />
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControlLabel
            control={
              <Checkbox
                name="notifyAdmin"
                checked={formik.values.notifyAdmin}
                onChange={formik.handleChange}
              />
            }
            label="Notify Admin"
          />

          {/* <FormControlLabel
            control={
              <Checkbox
                name="notifyEndUser"
                checked={formik.values.notifyEndUser}
                onChange={formik.handleChange}
              />
            }
            label="Notify End User"
          /> */}
        </Grid>
        <Grid item xs={4}>
          <Typography textAlign="left">
            Period of Inactivity to Terminate Session
            <CustomTooltip title="Specify the inactivity duration (in minutes) after which a session is terminated." />
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            type="number"
            fullWidth
            name="inactivityPeriod"
            value={formik.values.inactivityPeriod}
            onChange={formik.handleChange}
            error={
              formik.touched.inactivityPeriod &&
              Boolean(formik.errors.inactivityPeriod)
            }
            helperText={
              formik.touched.inactivityPeriod && formik.errors.inactivityPeriod
            }
          />
        </Grid>
        <Grid item xs={2} sx={{ alignSelf: "center" }}>
          <span style={{ paddingLeft: "12px" }}>minutes</span>
        </Grid>
      </Grid>
      <br />
      <Button
        type="submit"
        variant="contained"
        className="primary-button-filled"
      >
        Save
      </Button>
    </Box>
  );
};

export default SessionPolicy;
