import React from "react";
import NavigationTabs from "../../utility/NavigationTabs";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import PageTitle from "../../utility/PageTitle";
import { PRIVILEGES } from "../../../utils/Privileges";
export default function DeviceMonitoringWrapper() {

  const tabList = [
    {
      label: "Device Session Monitor",
      path: "/reporting/device-monitoring",
      requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
    },
    {
      label: "Login and Security Monitor",
      path: "/reporting/device-activity",
      requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
    },
    {
      label: "Active User Sessions",
      path: "/reporting/ad-user-session",
      requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
    },
  ];

  return (
    <div>
      <div className="main-title-heading-container">
        <PageTitle subheading="Monitor device uptime to optimize performance." /> 
      </div>
      <NavigationTabs tabList={tabList} />
      <Box sx={{ pt: 2 }}>
        <Outlet />
      </Box>
    </div>
  );
}
