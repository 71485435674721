import { properties } from "../properties";
import api from "../services/api";
import { getSubDomain } from "../utils/Helpers";

function authenticate(email, password) {
  return api.post(`${properties.gateway}/auth/login`, {
    email: email,
    password: password,
    orgName: getSubDomain()
  });
}

function validateOtp(email, password, otp) {
  return api.post(`${properties.gateway}/auth/validate-totp`, {
    email: email,
    password: password,
    orgName: getSubDomain(),
    totpCode: otp
  });
}

export { authenticate, validateOtp };
