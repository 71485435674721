import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../services/index";
import CookieService from "../../services/CookieService";
import MiniOrangeIcon from "../assets/icons/MiniOrangeIcon";
import {
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Avatar,
  Tooltip,
  IconButton,
  Divider,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  Button
} from "@mui/material";
import { routeHeadings, subHeadings } from "../../utils/data";
import {
  Settings,
  Logout,
  ShowChart,
  Dashboard,
  Pin
} from "@mui/icons-material";
import stringAvatar from "../utility/Avatar";
import SearchIcon from "@mui/icons-material/Search";
import ReportingIcon from "../assets/icons/ReportingIcon";
import DashboardIcon from "../assets/icons/DashboardIcon";
import PolicyIcon from "../assets/icons/PolicyIcon";
import CheckPrivilege from "../utility/CheckPrivilege";
import { ADMIN_PRIVILEGE, PRIVILEGES } from "../../utils/Privileges";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";

const NavigationBar = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.auth.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleAccountDetail = () => {
    navigate("/my-account");
  };

  const openAccountSettings = () => {
    navigate("/settings");
  };

  const logout = () => {
    dispatch(logoutUser());
  };
  // check current active route
  const isActive = (path) => {
    if (window.location.pathname === path) {
      return "active";
    } else {
      return "";
    }
  };
  const handleSwitchDashboard = () => {
    if (!location.pathname.startsWith("/reporting/")) {
      navigate("/reporting/usb-reporting");
    } else {
      navigate("/dashboard");
    }
  };

  const handleNavigateDashboard = () => {
    navigate("/dashboard");
  };

  const isReportingURL = location.pathname.startsWith("/reporting/");

  const guestLinks = (
    <>
      {!isActive("/login") && (
        <Link to={"login"} className="nav-link">
          <FontAwesomeIcon icon={faSignInAlt} /> Login
        </Link>
      )}
    </>
  );
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const userLinks = (
    <>
      <Toolbar sx={{ paddingLeft: "20px !important" }}>
        <IconButton
          onClick={handleNavigateDashboard}
          style={{ padding: 0 }}
          disableRipple
        >
          <MiniOrangeIcon />
        </IconButton>
        <Typography
          variant="h4"
          component="div"
          sx={{ ml: 1, color: "black", paddingLeft: "60px", fontSize: "20px" }}
        >
          Welcome, <b>{user.firstName}</b>
        </Typography>
      </Toolbar>
      <div style={{ display: "flex", alignItems: "center" }}>
        <CheckPrivilege requiredPrivileges={[PRIVILEGES.VIEW_REPORTS.name]}>
          <div style={{ paddingRight: "10px" }}>
            <Button
              startIcon={
                isReportingURL ? (
                  <DashboardIcon color="#0052CC" />
                ) : (
                  <PolicyIcon color="#0052CC" />
                )
              }
              className="reporting"
              sx={{ fontSize: "13px", color: "#0052CC", fontWeight: "600" }}
              onClick={handleSwitchDashboard}
            >
              {isReportingURL ? "Dashboard" : "Reporting"}
            </Button>
          </div>
        </CheckPrivilege>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleOpen}
            size="small"
            sx={{ mr: 1 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              {...stringAvatar(
                user.email,
                user.firstName[0],
                user.lastName ? user.lastName[0] : ""
              )}
            />
          </IconButton>
        </Tooltip>
      </div>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleAccountDetail}>
          <Avatar
            {...stringAvatar(
              user.email,
              user.firstName[0],
              user.lastName ? user.lastName[0] : ""
            )}
          />
          <ListItemText
            primary={
              user.lastName
                ? user.firstName + " " + user.lastName
                : user.firstName
            }
            secondary={user.email}
            secondaryTypographyProps={{ style: { fontSize: "small" } }}
          />
        </MenuItem>
        <Divider />
        <CheckPrivilege
          requiredPrivileges={[
            ADMIN_PRIVILEGE,
            PRIVILEGES.CONFIGURE_EMAIL_TEMPLATE.name
          ]}
        >
          <MenuItem onClick={() => navigate("/customization")}>
            <ListItemIcon>
              <DashboardCustomizeIcon fontSize="small" />
            </ListItemIcon>
            Customization
          </MenuItem>
        </CheckPrivilege>
        <MenuItem onClick={() => navigate("/my-account#2fa-configuration")}>
          <ListItemIcon>
            <Pin fontSize="small" />
          </ListItemIcon>
          Setup 2FA
        </MenuItem>
        <CheckPrivilege
          requiredPrivileges={[
            PRIVILEGES.CONFIGURE_ACTIVE_DIRECTORY.name,
            PRIVILEGES.CONFIGURE_PASSWORD_POLICY.name,
            PRIVILEGES.CONFIGURE_SMTP.name
          ]}
        >
          <MenuItem onClick={openAccountSettings}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
        </CheckPrivilege>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );

  return (
    <>
      {isLoggedIn ? (
        <Toolbar
          sx={{
            backgroundColor: "white",
            minHeight: "55px !important",
            borderBottom: "1px solid rgba(235 235 235 / 1)",
            boxShadow: "0px 4px 9px 0px rgba(0 0 0 / 0.04)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "0px !important"
          }}
        >
          {userLinks}
        </Toolbar>
      ) : null}
    </>
  );
};

export default NavigationBar;
