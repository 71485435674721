import { Navigate } from "react-router-dom";
import { ADMIN_PRIVILEGE, PRIVILEGES } from "./Privileges";
import { usbTypeMap } from "./Constants.js";
import { lazy } from "react";
import SAPReportList from "../components/reporting_dashboard/SAPReportList.js";
import DeviceMonitoringWrapper from "../components/reporting_dashboard/device_monitoring/DeviceMonitoringWrapper.js";
import SessionPolicy from "../components/ad_user_policy/SessionPolicy.js";
import AdUserAdaptivePolicy from "../components/ad_user_policy/AdUserAdaptivePolicy.js";
import AdUserPolicy from "../components/ad_user_policy/AdUserPolicy.js";
import AdUserActiveSession from "../components/reporting_dashboard/device_monitoring/AdUserActiveSession.js";

const Dashboard = lazy(() => import("../components/dashboard/Dashboard"));
const DashboardUserList = lazy(() =>
  import("../components/roles_and_admins/UserList")
);
const ClassificationList = lazy(() =>
  import("../components/data_classification/ClassificationList")
);
const PolicyList = lazy(() => import("../components/policy/PolicyList"));
const HttpPolicy = lazy(() => import("../components/policy/HttpPolicy"));
const UsbPolicy = lazy(() => import("../components/policy/UsbPolicy"));
const DesktopAppPolicy = lazy(() =>
  import("../components/policy/DesktopAppPolicy")
);
const BlockingCategories = lazy(() =>
  import("../components/policy/http_policy/domain_categories/BlockUrlList")
);
const DevicePage = lazy(() => import("../components/device/DevicePage"));
const DeviceApprovalRequests = lazy(() =>
  import("../components/device/DeviceApprovalRequests")
);
const GroupList = lazy(() => import("../components/groups/GroupList"));
const UserGroupList = lazy(() => import("../components/groups/UserGroupList"));
const DeviceGroupList = lazy(() =>
  import("../components/groups/DeviceGroupList")
);
const DictionaryRules = lazy(() =>
  import("../components/rule/DictionaryRules")
);
const UserList = lazy(() => import("../components/end_user/UserList"));
const ManageRolesAndUsers = lazy(() =>
  import("../components/roles_and_admins/ManageRolesAndUsers")
);
const RoleList = lazy(() => import("../components/roles_and_admins/RoleList"));
const ReportingList = lazy(() =>
  import("../components/reporting/ReportingList")
);
const EmailReportList = lazy(() =>
  import("../components/reporting/EmailReportList")
);
const DeviceReportList = lazy(() =>
  import("../components/reporting/DeviceReportList")
);
const UsbReportList = lazy(() =>
  import("../components/reporting_dashboard/UsbReporting")
);
const HttpReportList = lazy(() =>
  import("../components/reporting_dashboard/HttpReporting")
);
const DesktopAppReportList = lazy(() =>
  import("../components/reporting_dashboard/DesktopAppReporting")
);
const EthernetReportList = lazy(() =>
  import("../components/reporting_dashboard/EthernetReporting")
);
const PrinterReportList = lazy(() =>
  import("../components/reporting_dashboard/PrinterReporting.js")
);
const AgentStatusReport = lazy(() =>
  import("../components/reporting_dashboard/AgentStatusReport")
);
const DeviceMonitoring = lazy(() =>
  import(
    "../components/reporting_dashboard/device_monitoring/DeviceMonitoring.js"
  )
);
const DeviceActivity = lazy(() =>
  import(
    "../components/reporting_dashboard/device_monitoring/DeviceActivity.js"
  )
);
const ApprovalRequestList = lazy(() =>
  import("../components/approval_requests/ApprovalRequests")
);
const PolicyBreachAlertList = lazy(() =>
  import("../components/alerts/PolicyBreachAlertList")
);
const EmailTemplateEditorPage = lazy(() =>
  import("../components/email_templates/EmailTemplateEditorPage")
);
const EmailTemplatesList = lazy(() =>
  import("../components/email_templates/EmailTemplatesList")
);
const SmtpSettings = lazy(() =>
  import("../components/configurations/SmtpSettings")
);
const AccountPage = lazy(() => import("../components/AccountPage"));
const SettingsPage = lazy(() => import("../components/settings/SettingsPage"));
const PasswordConfiguration = lazy(() =>
  import("../components/password_configuration/Configuration")
);
const OrganizationalApps = lazy(() =>
  import("../components/application_blocking/ApplicationList")
);
const AppBlockingCategories = lazy(() =>
  import("../components/application_blocking/AppCategoryList")
);
const Customization = lazy(() =>
  import("../components/customization/Customization.js")
);
const BasicCustomization = lazy(() =>
  import("../components/customization/Basic.js")
);
const DownloadModulePage = lazy(() =>
  import("../components/DownloadModulePage")
);
const AdForm = lazy(() =>
  import("../components/user_store/active_directory/AdForm")
);
const BulkReport = lazy(() => import("../components/bulk_upload/BulkReport"));
const OrganizationalDomains = lazy(() =>
  import("../components/organizational_domains/OrganizationalDomainList")
);
const BlockUsb = lazy(() => import("../components/UsbBlocking/BlockUsbList"));
const RegistryConfigurationReport = lazy(() =>
  import("../components/registry_editor/RegistryConfigurationReport.js")
);
const MFAReportList = lazy(() =>
  import("../components/reporting_dashboard/MFAReportList.js")
);

const ROUTES = [
  { path: "/", element: Dashboard },
  { path: "/my-account", element: AccountPage },
  {
    path: "/view-users",
    element: UserList,
    requiredPrivileges: [PRIVILEGES.VIEW_USER_LIST.name]
  },
  {
    path: "/view-devices",
    element: DevicePage,
    requiredPrivileges: [PRIVILEGES.VIEW_DEVICE_LIST.name]
  },
  {
    path: "/device-approval-requests",
    element: DeviceApprovalRequests,
    requiredPrivileges: [PRIVILEGES.MANAGE_DEVICE_APPROVALS.name]
  },
  {
    path: "/classification-list",
    element: ClassificationList,
    requiredPrivileges: [PRIVILEGES.VIEW_CLASSIFICATION.name]
  },
  {
    path: "/rule-list",
    element: DictionaryRules,
    requiredPrivileges: [PRIVILEGES.VIEW_RULE.name]
  },
  {
    path: "/user-groups",
    element: UserGroupList,
    requiredPrivileges: [PRIVILEGES.VIEW_USER_GROUPS.name]
  },
  {
    path: "/device-groups",
    element: DeviceGroupList,
    requiredPrivileges: [PRIVILEGES.VIEW_DEVICE_GROUPS.name]
  },
  { path: "/dashboard", element: Dashboard },
  {
    path: "/usb",
    element: UsbPolicy,
    props: { usbType: usbTypeMap.usb },
    requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name, PRIVILEGES.VIEW_USB.name],
    children: [
      {
        path: "",
        element: BlockUsb,
        props: { usbType: usbTypeMap.usb },
        requiredPrivileges: [PRIVILEGES.VIEW_USB.name]
      },
      {
        path: "policy",
        element: PolicyList,
        requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name]
      }
    ]
  },
  {
    path: "/printer",
    element: UsbPolicy,
    props: { usbType: usbTypeMap.printer },
    requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name, PRIVILEGES.VIEW_USB.name],
    children: [
      {
        path: "",
        element: BlockUsb,
        props: { usbType: usbTypeMap.printer },
        requiredPrivileges: [PRIVILEGES.VIEW_USB.name]
      },
      {
        path: "policy",
        element: PolicyList,
        requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name]
      }
    ]
  },
  {
    path: "/ethernet-policy",
    element: PolicyList,
    requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name]
  },
  {
    path: "/email-policy",
    element: PolicyList,
    requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name]
  },
  {
    path: "/http",
    element: HttpPolicy,
    requiredPrivileges: [
      PRIVILEGES.VIEW_POLICY.name,
      PRIVILEGES.VIEW_DOMAIN_AND_CATEGORY.name
    ],
    children: [
      {
        path: "",
        element: BlockingCategories,
        requiredPrivileges: [PRIVILEGES.VIEW_DOMAIN_AND_CATEGORY.name]
      },
      {
        path: "policy",
        element: PolicyList,
        requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name]
      }
    ]
  },
  {
    path: "/desktop-app",
    element: DesktopAppPolicy,
    requiredPrivileges: [
      PRIVILEGES.VIEW_POLICY.name,
      PRIVILEGES.VIEW_APP.name,
      PRIVILEGES.VIEW_APP_CATEGORY.name
    ],
    children: [
      {
        path: "",
        element: OrganizationalApps,
        requiredPrivileges: [PRIVILEGES.VIEW_APP.name]
      },
      {
        path: "category",
        element: AppBlockingCategories,
        requiredPrivileges: [PRIVILEGES.VIEW_APP_CATEGORY.name]
      },
      {
        path: "policy",
        element: PolicyList,
        requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name]
      }
    ]
  },
  {
    path: "/ad-user-policy",
    element: AdUserPolicy,
    requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name],
    children: [
      {
        path: "session-policy",
        element: SessionPolicy
      },
      {
        path: "adaptive-policy",
        element: AdUserAdaptivePolicy,
        requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name]
      }
    ]
  },
  {
    path: "/reporting-list",
    element: ReportingList,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    path: "/reporting/email-reports",
    element: EmailReportList,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    path: "/device-reports",
    element: DeviceReportList,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    path: "/policy-relaxation",
    element: ApprovalRequestList,
    requiredPrivileges: [PRIVILEGES.VIEW_APPROVAL.name]
  },
  {
    path: "/organizational-domains",
    element: OrganizationalDomains,
    requiredPrivileges: [PRIVILEGES.VIEW_ORGANIZATIONAL_DOMAIN.name]
  },
  {
    path: "/settings",
    element: SettingsPage,
    requiredPrivileges: [
      PRIVILEGES.CONFIGURE_ACTIVE_DIRECTORY.name,
      PRIVILEGES.CONFIGURE_PASSWORD_POLICY.name,
      PRIVILEGES.CONFIGURE_SMTP.name
    ],
    children: [
      {
        path: "",
        element: () => <Navigate to="ad" />,
        requiredPrivileges: [PRIVILEGES.CONFIGURE_ACTIVE_DIRECTORY.name]
      },
      {
        path: "ad",
        element: AdForm,
        requiredPrivileges: [PRIVILEGES.CONFIGURE_ACTIVE_DIRECTORY.name]
      },
      {
        path: "smtp",
        element: SmtpSettings,
        requiredPrivileges: [PRIVILEGES.CONFIGURE_SMTP.name]
      },
      {
        path: "password-policy",
        element: PasswordConfiguration,
        requiredPrivileges: [PRIVILEGES.CONFIGURE_PASSWORD_POLICY.name]
      }
    ]
  },
  {
    path: "/customization/email-templates/edit",
    element: EmailTemplateEditorPage,
    requiredPrivileges: [PRIVILEGES.CONFIGURE_EMAIL_TEMPLATE.name]
  },
  {
    path: "/policy-breach-alert",
    element: PolicyBreachAlertList,
    requiredPrivileges: [PRIVILEGES.VIEW_NOTIFICATION.name]
  },
  {
    path: "/registry-editor",
    element: RegistryConfigurationReport,
    requiredPrivileges: [PRIVILEGES.CONFIGURE_REGISTRY.name]
  },
  {
    path: "/manage",
    element: ManageRolesAndUsers,
    children: [
      {
        path: "",
        element: () => <Navigate to="role" />
      },
      {
        path: "role",
        element: RoleList
      },
      {
        path: "user",
        element: DashboardUserList
      }
    ]
  },
  {
    path: "/customization",
    element: Customization,
    requiredPrivileges: [
      ADMIN_PRIVILEGE,
      PRIVILEGES.CONFIGURE_EMAIL_TEMPLATE.name
    ],
    children: [
      {
        path: "basic",
        element: BasicCustomization
      },
      {
        path: "email-templates",
        element: EmailTemplatesList,
        requiredPrivileges: [PRIVILEGES.CONFIGURE_EMAIL_TEMPLATE.name]
      }
    ]
  },
  {
    path: "/reporting/bulk-uploads",
    element: BulkReport,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    path: "/reporting/usb-reporting",
    element: UsbReportList,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    path: "/reporting/printer-reporting",
    element: PrinterReportList,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    path: "/reporting/http-reporting",
    element: HttpReportList,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    path: "/reporting/desktop-apps-reporting",
    element: DesktopAppReportList,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    path: "/reporting/ethernet-reporting",
    element: EthernetReportList,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    path: "/reporting/mfa-reports",
    element: MFAReportList,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    path: "/reporting/sap-reports",
    element: SAPReportList,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    path: "/reporting",
    element: DeviceMonitoringWrapper,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name],
    children: [
      {
        path: "device-monitoring",
        element: DeviceMonitoring,
        requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
      },
      {
        path: "device-activity",
        element: DeviceActivity,
        requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
      },
      {
        path: "ad-user-session",
        element: AdUserActiveSession,
        requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
      }
    ]
  },
  {
    path: "/reporting/agent-status-report",
    element: AgentStatusReport,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  }
];

export { ROUTES };
