const ACTION = {
  BLOCK: "BLOCK",
  NOTIFY: "NOTIFY",
  LOG: "LOG",
  QUARANTINE: "QUARANTINE",
  ALLOW: "ALLOW",
  NONE: "NONE"
};

const RISK_LEVEL = {
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH"
};

export const REMOTE_LOCK_STATUS = {
  APPLIED: "APPLIED",
  REQUESTED: "REQUESTED"
};

export const REMOTE_WIPE_STATUS = {
  APPLIED: "APPLIED",
  REQUESTED: "REQUESTED"
};

const CRITERIA_TYPE = {
  REGEX: "REGEX",
  KEYWORD: "KEYWORD",
  PREDEFINED_ALGORITHM: "PREDEFINED_ALGORITHM"
};

const APPROVAL_STATUS = {
  EXPIRED: "Expired",
  REVOKED: "Revoked",
  ACTIVE: "Active",
  UPCOMING: "Upcoming"
};

const FEATURES = {
  USB: "USB",
  ETHERNET: "ETHERNET",
  CLIPBOARD: "CLIPBOARD",
  SCREENSHOT: "SCREENSHOT",
  SITE_BLOCKING: "SITE_BLOCKING",
  APP_BLOCKING: "APP_BLOCKING",
  EMAIL_BLOCKING: "EMAIL_BLOCKING",
  PASSWORD_CONFIGURATION: "PASSWORD_CONFIGURATION",
  SMTP_CONFIGURATION: "SMTP_CONFIGURATION",
  ACTIVE_DIRECTORY: "ACTIVE_DIRECTORY",
  CLASSIFICATION: "CLASSIFICATION",
  USERS: "USERS",
  DEVICES: "DEVICES",
  GENERAL: "GENERAL",
  MANAGE_ROLES_AND_USERS: "MANAGE_ROLES_AND_USERS"
};

const GROUP_TYPE = {
  DEVICE: "DEVICE",
  USER: "USER"
};

const EVENT_TYPE = {
  SIGN_IN: "SIGN_IN",
  SIGN_OUT: "SIGN_OUT"
};

const EMAIL_TEMPLATE_NAMES = {
  PASSWORD_RESET_EMAIL: "Password Reset Email",
  TIME_BOUND_ACCESS_APPROVED: "Time Bound Access Approved",
  TIME_BOUND_ACCESS_REVOKED: "Time Bound Access Revoked",
  EMAIL_POLICY_BREACHED: "Email Policy Breach",
  DEVICE_POLICY_BREACHED: "Device Policy Breach"
};

const DIALOG_TYPES = {
  ALERT: "ALERT",
  CONFIRM: "CONFIRM",
  INPUT: "INPUT"
};

const DEVICE_TYPE = {
  BYOD: "BYOD",
  COMPANY_OWNED: "COMPANY_OWNED"
};

const MESSAGES = {
  LOGOUT_MESSAGE: "You have been logged out. Please log in again to continue !",
  GENERIC_EXCEPTION_MESSAGE: "Something went wrong",
  INVALID_SUBDOMAIN: "Invalid Subdomain",
  NOT_ALLOWED: "You are not allowed to perform this action.",
  FETCHING_ERROR_DEVICE_GROUPS: "Error in fetching device groups.",
  FETCHING_ERROR_USER_GROUPS: "Error in fetching user groups."

};
const AGENT_STATUS = {
  STARTED: "Started",
  STOPPED: "Stopped",
  UNINSTALLED: "Uninstalled"
};

const DESTINATION_ID_TO_NAME = {
  1: "USB",
  2: "EMAIL",
  3: "HTTP",
  6: "DESKTOP APPS",
  7: "ETHERNET",
  8: "PRINTER"
};

const DESTINATION_ID_TO_PATH = {
  1: "/usb/policy",
  2: "/email-policy",
  3: "/http/policy",
  4: "/clipboard-policy",
  5: "/screenshot-policy",
  6: "/desktop-app/policy",
  7: "/ethernet-policy",
  8: "/printer/policy"
}

const JOB_NAMES = {
  IMPORT_END_USER: "IMPORT_END_USER",
  IMPORT_DOMAIN_AND_CATEGORIES: "IMPORT_DOMAIN_AND_CATEGORIES"
};

const JOB_HEADINGS = {
  IMPORT_END_USER: "Bulk user CSV upload",
  IMPORT_DOMAIN_AND_CATEGORIES: "Bulk domain CSV upload"
};

const usbTypeMap = {
  usb: {
    type: "STORAGE",
    title: "USB Storage Device",
    path: "/usb"
  },
  printer: {
    type: "PRINTER",
    title: "USB Printer",
    path: "/printer"
  }
};

const ROLE_ID_MAP = {
  ADMIN: 1
};

const DEVICE_APPROVAL_STATUS = {
  APPROVED: "Approved",
  DENIED: "Denied",
  REQUESTED: "Requested"
};

const DEVICE_APPROVAL_STATUS_KEYS = {
  APPROVED: "APPROVED",
  DENIED: "DENIED",
  REQUESTED: "REQUESTED"
};
const REGISTRY_ACTION = {
  ADD_KEY: { value: "ADD_KEY", label: "Add Key" },
  DELETE_KEY: { value: "DELETE_KEY", label: "Delete Key" },
  WRITE_VALUE: { value: "WRITE_VALUE", label: "Write Value" },
  DELETE_VALUE: { value: "DELETE_VALUE", label: "Delete Value" }
};

const MFA_STATUS = {
  TOTP_REQUIRED: "MFA Enabled, Required TOTP"
}

const TIME_ZONE_OPTIONS = [
  { value: "-12:00", label: "(GMT -12:00) Eniwetok, Kwajalein" },
  { value: "-11:00", label: "(GMT -11:00) Midway Island, Samoa" },
  { value: "-10:00", label: "(GMT -10:00) Hawaii" },
  { value: "-09:00", label: "(GMT -9:00) Alaska" },
  { value: "-08:00", label: "(GMT -8:00) Pacific Time (US & Canada)" },
  { value: "-07:00", label: "(GMT -7:00) Mountain Time (US & Canada)" },
  { value: "-06:00", label: "(GMT -6:00) Central Time (US & Canada), Mexico City" },
  { value: "-05:00", label: "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima" },
  { value: "-04:00", label: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz" },
  { value: "-03:30", label: "(GMT -3:30) Newfoundland" },
  { value: "-03:00", label: "(GMT -3:00) Brazil, Buenos Aires, Georgetown" },
  { value: "-02:00", label: "(GMT -2:00) Mid-Atlantic" },
  { value: "-01:00", label: "(GMT -1:00 hour) Azores, Cape Verde Islands" },
  { value: "+00:00", label: "(GMT) Western Europe Time, London, Lisbon, Casablanca" },
  { value: "+01:00", label: "(GMT +1:00 hour) Brussels, Copenhagen, Madrid, Paris" },
  { value: "+02:00", label: "(GMT +2:00) Kaliningrad, South Africa" },
  { value: "+03:00", label: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg" },
  { value: "+03:30", label: "(GMT +3:30) Tehran" },
  { value: "+04:00", label: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi" },
  { value: "+04:30", label: "(GMT +4:30) Kabul" },
  { value: "+05:00", label: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent" },
  { value: "+05:30", label: "(GMT +5:30) Indian Standard (Bombay, Calcutta, Madras, New Delhi)" },
  { value: "+05:45", label: "(GMT +5:45) Kathmandu" },
  { value: "+06:00", label: "(GMT +6:00) Almaty, Dhaka, Colombo" },
  { value: "+07:00", label: "(GMT +7:00) Bangkok, Hanoi, Jakarta" },
  { value: "+08:00", label: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong" },
  { value: "+09:00", label: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk" },
  { value: "+09:30", label: "(GMT +9:30) Adelaide, Darwin" },
  { value: "+10:00", label: "(GMT +10:00) Eastern Australia, Guam, Vladivostok" },
  { value: "+11:00", label: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia" },
  { value: "+12:00", label: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka" },
];

const WEEKDAYS = [
  { label: "Monday", value: "MONDAY" },
  { label: "Tuesday", value: "TUESDAY" },
  { label: "Wednesday", value: "WEDNESDAY" },
  { label: "Thursday", value: "THURSDAY" },
  { label: "Friday", value: "FRIDAY" },
  { label: "Saturday", value: "SATURDAY" },
  { label: "Sunday", value: "SUNDAY" }
];


export {
  ACTION,
  RISK_LEVEL,
  CRITERIA_TYPE,
  APPROVAL_STATUS,
  FEATURES,
  EMAIL_TEMPLATE_NAMES,
  DIALOG_TYPES,
  DEVICE_TYPE,
  MESSAGES,
  DESTINATION_ID_TO_NAME,
  DESTINATION_ID_TO_PATH,
  GROUP_TYPE,
  EVENT_TYPE,
  JOB_HEADINGS,
  JOB_NAMES,
  AGENT_STATUS,
  usbTypeMap,
  ROLE_ID_MAP,
  DEVICE_APPROVAL_STATUS,
  DEVICE_APPROVAL_STATUS_KEYS,
  REGISTRY_ACTION,
  MFA_STATUS,
  TIME_ZONE_OPTIONS,
  WEEKDAYS
};
